import store from "../store";

export default [
  {
    path: "/login",
    name: "LoginComponent",
    component: () => import("../components/access/LoginComponent.vue"),
  },
  {
    path: "/",
    name: "IndexComponent",
    component: () => import("../components/dashboard/IndexComponent.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/posinicio",
    name: "PosIndex",
    component: () => import("../components/dashboard/IndexComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/pos"]) {
        store.commit("master/setMenu", store.state.pos.menu);
        store.dispatch("pos/dataPrimaryPos");
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
      }
    },
  },
  {
    path: "/punto_venta",
    name: "POSComponent",
    component: () => import("../components/pos/POSComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/pos"]) {
        //if(1==2) {
        store.commit("master/setMenu", store.state.pos.menu);
        next();
        //}
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
      }
    },
  },
  {
    path: "/cotizacion/:cotizacion_id/:work_effort_id?",
    name: "DetailQuotationComponent",
    component: () => import("../components/pos/DetailQuotationComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/pos"]) {
        store.commit("master/setMenu", store.state.pos.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
      }
    },
  },
  {
    path: "/cajas_usuario",
    name: "CajasUsuarioComponent",
    component: () => import("../components/pos/CajasUsuarioComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/pos"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: { ...store.state.access.deniedCompany },
        });
      }
    },
  },
  {
    path: "/rutas",
    name: "ListaRutasComponent",
    component: () => import("../components/pos/RutasComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/store"]) {
        if (store.getters["access/pos"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/bodega",
    name: "BodegaIndex",
    component: () => import("../components/dashboard/IndexComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/store"]) {
        store.commit("master/setMenu", store.state.bodega.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },
  {
    path: "/lista_transferencias",
    name: "ListaTransferenciasComponent",
    component: () =>
      import("../components/bodega/ListaTransferenciasComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/store"]) {
        if (store.getters["access/listaTransferencias"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/transacciones_caja",
    name: "CashTransactionsComponent",
    component: () => import("../components/pos/CashTransactionsComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/pos"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/compras",
    name: "ComprasIndex	",
    component: () => import("../components/dashboard/IndexComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/purchases"]) {
        store.commit("master/setMenu", store.state.purchases.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/proveedores/:partyId?/:workEffortId?",
    name: "SupplierComponent",
    component: () => import("../components/purchases/SuppliersComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/purchases"]) {
        if (store.getters["access/suppliersList"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/cotizaciones-proveedores",
    name: "QuoteSuppliersComponent",
    component: () =>
      import("../components/purchases/QuoteSuppliersComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/purchases"]) {
        if (store.getters["access/supplierQuotes"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/verificar-proveedor/:partyId/:workEffortId?",
    name: "CheckSupplierCompnent",
    component: () =>
      import("../components/purchases/CheckSupplierCompnent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/purchases"]) {
        if (store.getters["access/validateSupplier"]) {
          store.commit("master/setMenu", store.state.purchases.menu);
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/anticipo-proveedor/:paymentGroupId/:workEffortId",
    name: "AproveAdvanceSupplierComponent",
    component: () =>
      import("../components/purchases/AproveAdvanceSupplierComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/purchases"]) {
        if (store.getters["access/aproveAdvance"]) {
          store.commit("master/setMenu", store.state.contabilidad.menu);
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/procesar-anticipo-proveedor/:paymentGroupId/:workEffortId",
    name: "ManageSupplierPaymentComponent",
    component: () =>
      import("../components/purchases/ManageSupplierPaymentComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/purchases"]) {
        if (store.getters["access/manageSupplierPayment"]) {
          store.commit("master/setMenu", store.state.contabilidad.menu);
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/administracion",
    name: "Administracion",
    component: () => import("../components/dashboard/IndexComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/admin"]) {
        store.commit("master/setMenu", store.state.administration.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/listado-usuarios",
    name: "ListUsersComponent",
    component: () =>
      import("../components/administration/ListUsersComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/admin"]) {
        if (store.getters["access/adminUsers"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/administracion-grupos",
    name: "ListPartyGroupsComponent",
    component: () =>
      import("../components/administration/ListPartyGroupsComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/admin"]) {
        if (store.getters["access/adminGroups"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/security_groups",
    name: "SecurityGroupsComponent",
    component: () =>
      import("../components/administration/SecurityGroupsComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/admin"]) {
        if (store.getters["access/adminGroups"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/administracion-tareas",
    name: "ListTask",
    component: () => import("../components/administration/ListTask.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/admin"]) {
        if (store.getters["access/adminTask"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/contabilidad",
    name: "ListaTareasComponent",
    component: () => import("../components/dashboard/IndexComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        store.commit("master/setMenu", store.state.contabilidad.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: { ...store.state.access.denied },
        });
      }
    },
  },
  {
    path: "/lista_cuentas",
    name: "ListaCuentasComponent",
    component: () =>
      import("../components/contabilidad/ListaCuentasComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        if (store.getters["access/listaCuentasContables"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: { ...store.state.access.denied },
        });
      }
    },
  },
  {
    path: "/cajas",
    name: "CajasComponent",
    component: () => import("../components/contabilidad/CajasComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/cajas"]) {
        if (store.getters["access/listaCajasContabilidad"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: { ...store.state.access.denied },
        });
      }
    },
  },
  {
    path: "/lista_cajas",
    name: "CajasAdminComponent",
    component: () => import("../components/pos/CajasAdminComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/pos"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: { ...store.state.access.denied },
        });
      }
    },
  },
  {
    path: "/ver-caja/:cajaId",
    name: "FormCajaComponent",
    component: () => import("../components/contabilidad/FormCajaComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/store"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: { ...store.state.access.denied },
        });
      }
    },
  },
  {
    path: "/lista_facturas_venta",
    name: "FacturasVentaComponent",
    component: () =>
      import("../components/contabilidad/FacturasVentaComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: { ...store.state.access.denied },
        });
      }
    },
  },
  {
    path: "/libro-mayor-saldos",
    name: "LedgerWithBlancesComponent",
    component: () =>
      import("../components/contabilidad/LedgerWithBlancesComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        if (store.getters["access/ledgerWithBalancesReport"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/reporte-unificado-gastos",
    name: "UnifiedExpenseReportComponent",
    component: () =>
      import("../components/contabilidad/UnifiedExpenseReportComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        if (store.getters["access/unifiedExpenseReport"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/comprobantes-electronicos-recibidos",
    name: "ElectronicsDocumentsReceivedComponent",
    component: () =>
      import(
        "../components/contabilidad/ElectronicsDocumentsReceivedComponent.vue"
      ),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        if (store.getters["access/electronicDocumentList"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/lista_facturas",
    name: "AdminFacturasVentaComponent",
    component: () =>
      import("../components/contabilidad/FacturasVentaComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: { ...store.state.access.denied },
        });
      }
    },
  },
  {
    path: "/lista_facturas_compra",
    name: "FacturasCompraComponent",
    component: () =>
      import("../components/contabilidad/FacturasCompraComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: { ...store.state.access.denied },
        });
      }
    },
  },
  {
    path: "/lista_facturas_rol",
    name: "FacturasRolComponent",
    component: () =>
      import("../components/contabilidad/FacturasRolComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: { ...store.state.access.denied },
        });
      }
    },
  },
  {
    path: "/cuentas_por_cobrar",
    name: "CuentasPorCobrarComponent",
    component: () =>
      import("../components/contabilidad/CuentasPorCobrarComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/posAdminCuentasPorCobrar"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: { ...store.state.access.denied },
        });
      }
    },
  },
  {
    path: "/factura-venta/:invoiceId",
    name: "InvoiceFormComponent",
    component: () =>
      import("../components/contabilidad/InvoiceFormComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/store"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: { ...store.state.access.denied },
        });
      }
    },
  },
  {
    path: "/arqueo_bodega/",
    name: "ArqueoBodegaComponent",
    component: () => import("../components/bodega/ArqueoBodegaComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/store"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: { ...store.state.access.denied },
        });
      }
    },
  },
  {
    path: "/inventario-por-caducar",
    name: "ListExpiredAndExpiringInvetoryComponent",
    component: () =>
      import(
        "../components/bodega/ListExpiredAndExpiringInvetoryComponent.vue"
      ),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        if (store.getters["access/listaInventarioPorCaducar"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/factura-compra/:invoiceId",
    name: "InvoiceCompraFormComponent",
    component: () =>
      import("../components/contabilidad/InvoiceCompraFormComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        if (store.getters["access/listaInventarioPorCaducar"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/lista_psicotropicos/",
    name: "ReporteRecetasPsicotropicos",
    component: () =>
      import("../components/bodega/ReporteRecetasPsicotropicos.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/store"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: { ...store.state.access.denied },
        });
      }
    },
  },
  {
    path: "/arqueo_diario/",
    name: "ArqueoDiarioComponent",
    component: () => import("../components/bodega/ArqueoDiarioComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/store"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: { ...store.state.access.denied },
        });
      }
    },
  },
  {
    path: "/balance_general",
    name: "BalanceGeneralComponent",
    component: () =>
      import("../components/contabilidad/BalanceGeneralComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/estado_resultados",
    name: "EstadoResultadosComponent",
    component: () =>
      import("../components/contabilidad/EstadoResultadosComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/productos",
    name: "ProductsComponent",
    component: () =>
      import("../components/administration/ProductsComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/admin"]) {
        if (store.getters["access/listaProductos"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/categoria-producto",
    name: "ProductsCategoryComponent",
    component: () =>
      import("../components/administration/ProductsCategoryComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/admin"]) {
        if (store.getters["access/listaCategoriasProdutos"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/clientes/:partyId?/:workEffortId?",
    name: "ClientesComponent",
    component: () => import("../components/pos/ClientesComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/pos"]) {
        if (store.getters["access/pos"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/prescriptores",
    name: "PrescriptoresComponent",
    component: () => import("../components/pos/PrescriptoresComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/posAdminPrescriptor"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: { ...store.state.access.denied },
        });
      }
    },
  },
  {
    path: "/promociones",
    name: "PromotionComponent",
    component: () =>
      import("../components/administration/PromotionComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/admin"]) {
        if (store.getters["access/promotions"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/acuerdos",
    name: "AgreementComponent",
    component: () =>
      import("../components/administration/AgreementComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/admin"]) {
        if (store.getters["access/agreement"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/regla-precio",
    name: "PriceRulesComponent",
    component: () =>
      import("../components/administration/PriceRulesComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/admin"]) {
        if (store.getters["access/priceRules"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/servicios-terceros",
    name: "ReporteServiciosAplicacionComponent",
    component: () =>
      import(
        "../components/contabilidad/ReporteServiciosAplicacionComponent.vue"
      ),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/conciliacion_banco",
    name: "ConciliacionBancariaComponent",
    component: () =>
      import("../components/contabilidad/ConciliacionBancariaComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/modifica-envio-cotizacion/:order_id/:work_effort_id",
    name: "ReagendaCotizacionComponent",
    component: () =>
      import("../components/pos/ReagendaCotizacionComponent.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/periodos_contables",
    name: "PeriodoContableComponent",
    component: () =>
      import("../components/contabilidad/PeriodoContableComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/precios_cuatrimestre",
    name: "ReportePreciosCuatrimestreComponent",
    component: () =>
      import("../components/administration/ReportePreciosCuatrimComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/preciosCuatrimestre"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/kardex_producto",
    name: "KardexProductoComponent",
    component: () => import("../components/bodega/KardexProductoComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/kardexFisicoProducto"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/pagos_recibidos",
    name: "PagosRecibidosComponent",
    component: () =>
      import("../components/contabilidad/PagosRecibidosComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/pagosRecibidos"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/administracion-web",
    name: "AdministracionWeb",
    component: () => import("../components/ecommerce/IconsEcommerceWeb.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/admin"]) {
        store.commit("master/setMenu", store.state.administration_web.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/ver-pago/:pagoId",
    name: "FormVerPagoComponent",
    component: () =>
      import("../components/contabilidad/FormVerPagoComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/store"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: { ...store.state.access.denied },
        });
      }
    },
  },

  {
    path: "/slider-home-ecommerce",
    name: "SliderHomeEcommerce",
    component: () => import("../components/ecommerce/SliderHomeEcommerce.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/admin"]) {
        store.commit("master/setMenu", store.state.administration_web.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/concatos-webcommerce",
    name: "ContactosWebCommerce",
    component: () => import("../components/ecommerce/ContactosWebCommerce.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/admin"]) {
        store.commit("master/setMenu", store.state.administration_web.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/personal-webcommerce",
    name: "PersonalWebCommerce",
    component: () => import("../components/ecommerce/PersonalWebCommerce.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/admin"]) {
        store.commit("master/setMenu", store.state.administration_web.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/direcciones-webcommerce",
    name: "DierccionesWebCommerce",
    component: () =>
      import("../components/ecommerce/DierccionesWebCommerce.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/admin"]) {
        store.commit("master/setMenu", store.state.administration_web.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/productos-webcommerce",
    name: "ProductsWebCommerce",
    component: () => import("../components/ecommerce/ProductsWebCommerce.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/admin"]) {
        store.commit("master/setMenu", store.state.administration_web.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/pagos-emitidos",
    name: "PagosProveedores",
    component: () =>
      import("../components/contabilidad/PagosProveedoresComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/ver-pago-emitido/:pagoId",
    name: "FormVerPagoSalienteComponent",
    component: () =>
      import("../components/contabilidad/FormVerPagoSalienteComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/store"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: { ...store.state.access.denied },
        });
      }
    },
  },

  {
    path: "/reembolsos-clientes",
    name: "ReembolsosComponent",
    component: () =>
      import("../components/contabilidad/ReembolsosComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/organizaciones",
    name: "Organizaciones",
    component: () =>
      import("../components/contabilidad/OrganizacionComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/retenciones",
    name: "RetencionesEmitidas",
    component: () =>
      import("../components/contabilidad/RetencionesEmitidasComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/retencion-emitida/:paymentId",
    name: "RetencionEmitidaForm",
    component: () =>
      import("../components/contabilidad/RetencionEmitidaFormComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/payment-group/",
    name: "OrdenesPago",
    component: () => import("../components/contabilidad/OrdenesPago.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/orden-pago/:paymentGroupId",
    name: "OrdenPagoForm",
    component: () => import("../components/contabilidad/OrdenesPago.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/ver-factura-compra/:invoiceId",
    name: "FacturasCompraForm",
    component: () =>
      import("../components/contabilidad/FacturasCompraComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: { ...store.state.access.denied },
        });
      }
    },
  },

  {
    path: "/nuevo-pago-recibido/",
    name: "FormNuevoPagoRecibido",
    component: () =>
      import("../components/contabilidad/FormNuevoPagoRecibido.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: { ...store.state.access.denied },
        });
      }
    },
  },

  {
    path: "/inicio-crm",
    name: "InicioCrm",
    component: () => import("../components/crm/InicioCrm.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/lista-oportunidades",
    name: "ListaOportunidades",
    component: () => import("../components/crm/ListaOportunidades.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/lista-leads",
    name: "ListaLeads",
    component: () => import("../components/crm/ListaLeads.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/pipelines",
    name: "Pipelines",
    component: () => import("../components/crm/Pipelines.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/lista-ordenes-servicio",
    name: "Ordenes de servicio",
    component: () => import("../components/crm/OrdenesServicio.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/lista-fuentes-leads",
    name: "Fuentes de Leads",
    component: () => import("../components/crm/FuentesLeads.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/horario_trabajo",
    name: "HorarioTrabajoComponent",
    component: () => import("../components/crm/HorarioTrabajoComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/horario_empleado",
    name: "HorariosEmpleadosComponent",
    component: () => import("../components/crm/HorarioEmpleadosComponent"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/reasignacion-evento",
    name: "ReasignacionEventoComponent",
    component: () => import("../components/crm/ReasignacionEventoComponent"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/crm_campania",
    name: "Campaña de marketing",
    component: () => import("../components/crm/Campaniamk.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/crm_cotizacion",
    name: "CrmCotizacion",
    component: () => import("../components/crm/Cotizacion.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/crm_prospectos",
    name: "Prospectos",
    component: () => import("../components/crm/Prospectos.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/tipo_evento",
    name: "TipoEventoComponent",
    component: () => import("../components/crm/TipoEventoComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/configuracion_servicio",
    name: "ConfiguracionServicioComponent",
    component: () =>
      import("../components/crm/ConfiguracionServicioComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/evento",
    name: "EventosComponent",
    component: () => import("../components/crm/EventosComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/oportunidad_kb",
    name: "OportunidadKanban",
    component: () => import("../components/crm/OportunidadKanban.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/lead_form/:partyId",
    name: "LeadForm",
    component: () => import("../components/crm/LeadForm.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/contacto_form/:partyId",
    name: "ContactoForm",
    component: () => import("../components/crm/ContactoForm.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/oportunidad_form/:sales_opportunity_id",
    name: "OportunidadForm",
    component: () => import("../components/crm/OportunidadForm.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/crm_workflows",
    name: "CrmWorkflow",
    component: () => import("../components/crm/Workflow.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/template_docs",
    name: "TemplateDocs",
    component: () => import("../components/crm/TemplateDoc.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/template_email",
    name: "TemplateEmails",
    component: () => import("../components/crm/TemplateEmail.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/evento_op/:salesOppId/:clienteId",
    name: "EventosOportComponent",
    component: () => import("../components/crm/EventosComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/evento_form/:workEffortId",
    name: "WorkEffortForm",
    component: () => import("../components/crm/EventoForm.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/genera-orden-servicio/:quoteId/:orderId?/:pacienteId?",
    name: "GeneraOrdenForm",
    component: () =>
      import("../components/crm/GeneraOrdenServicioComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/genera-orden-servicio-manual/:quoteId",
    name: "GeneraOrdenServicioManual",
    component: () =>
      import("../components/crm/GeneraOrdenServicioManualComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/checkout_cot/:quoteId",
    name: "CheckoutCotizacion",
    component: () => import("../components/crm/CotizacionCheckout.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/libro_banco",
    name: "LibroBancos",
    component: () =>
      import("../components/contabilidad/ConciliacionBancariaComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/libroBancos"]) {
        store.commit("master/setMenu", store.state.contabilidad.menu);

        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/estados_evento",
    name: "EstadosEventos",
    component: () => import("../components/crm/EventoEstado.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/eventos2",
    name: "CalendarioComponent",
    component: () => import("../components/crm/CalendarioComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/unificar_party",
    name: "UnificarParty",
    component: () => import("../components/crm/UnificarParty.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/plazos_pago",
    name: "PlazosPago",
    component: () => import("../components/crm/TipoPlazo.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/product_store",
    name: "ProductStore",
    component: () => import("../components/crm/ProductStore.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/formularios",
    name: "FormularioComponent",
    component: () => import("../components/crm/FormularioComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/asientoContableVista/:acctgTransId",
    name: "AsientoContableVista",
    component: () =>
      import("../components/contabilidad/AsientoContableVista.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        store.commit("master/setMenu", store.state.contabilidad.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/reporte_ventas_producto",
    name: "ReporteVentasProducto",
    component: () =>
      import("../components/contabilidad/ReporteVentasProducto.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/reporteVentasProducto"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/reporte_closeup",
    name: "ReporteCloseup",
    component: () => import("../components/administration/ReporteCloseup.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/reporteCloseup"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/dispositivos",
    name: "Dispositivos",
    component: () => import("../components/crm/DispositivoCom.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/waDispositivos"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/contactos_wa",
    name: "ContactosWa",
    component: () => import("../components/crm/ContactosWatsapp.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/waContactos"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/template_message",
    name: "TemplateMessage",
    component: () => import("../components/crm/TemplateMessage.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/plantillasMensajes"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/mensajes_contacto/:num_telef",
    name: "MensajesContacto",
    component: () => import("../components/crm/MensajesContacto.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/waMensajesContacto"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/security_permission",
    name: "SecurityPermissionComponent",
    component: () =>
      import("../components/administration/SecurityPermission.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/admin"]) {
        if (store.getters["access/adminGroups"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/crearAsientoSimple/:acctgTransId?",
    name: "AsientoContableSimpleForm",
    component: () =>
      import("../components/contabilidad/AsientoContableSimpleForm.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/asientoContableForm/:acctgTransId?",
    name: "AsientoContableForm",
    component: () =>
      import("../components/contabilidad/AsientoContableForm.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/asientoContableLista",
    name: "AsientoContableLista",
    component: () =>
      import("../components/contabilidad/AsientosContablesLista.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/balanceMensual",
    name: "BalanceMensual",
    component: () => import("../components/contabilidad/BalanceMensual.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/contabilidad"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/contratos",
    name: "ContratosComponent",
    component: () => import("../components/crm/ContratosComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
  {
    path: "/administracion-tiendas",
    name: "TiendasComponent",
    component: () =>
      import("../components/administration/TiendasComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/admin"]) {
        if (store.getters["access/adminUsers"]) {
          next();
        } else {
          store.dispatch("master/alertNotification", {
            param: store.state.access.deniedUser,
          });
          next(from.path);
        }
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/campaniaWhatsapp",
    name: "CampaniaWhatsapp",
    component: () => import("../components/crm/CampaniaWhatsapp.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/waCampania"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/crm_contactos",
    name: "Contactos",
    component: () => import("../components/crm/ContactosTelefonos.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/adminCrm"]) {
        store.commit("master/setMenu", store.state.crm.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/reservas_psicotropicos",
    name: "ReservasPsicotropicos",
    component: () => import("../components/administration/RerporteReservasPsico.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/reporteCloseup"]) {
        
        store.commit("master/setMenu", store.state.administration.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },

  {
    path: "/ventas_psicotropicos",
    name: "VentasPsicotropicos",
    component: () => import("../components/administration/VentasPsicotropicos.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/reporteCloseup"]) {
        
        store.commit("master/setMenu", store.state.administration.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },


  {
    path: "/rep_compras_prov",
    name: "ReporteComprasProveedor",
    component: () => import("../components/contabilidad/ReporteComprasProveedor.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {    
      
      if (store.getters["access/reporteComprasProveedor"]) {
        
        store.commit("master/setMenu", store.state.contabilidad.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },


  {
    path: "/rep_promo_compras",
    name: "ReportePromocionesCompras",
    component: () => import("../components/administration/ReportePromocionesCompras.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/reportePromoCompras"]) {
        
        store.commit("master/setMenu", store.state.administration.menu);
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedCompany,
        });
        next(from.path);
      }
    },
  },
];
